import React from "react";

import Layout from "../../layout";
import SEO from "../../components/seo";
import useRoutes from "../../hooks/redux/useRoutes";
import useAuth from "../../hooks/redux/useAuth";

const MyRoutes = () => {
    const [auth] = useAuth();
    const [{ routes }] = useRoutes();

    const startedRoutes =
        auth?.data?.started_routes?.map((id) => {
            return routes.find((route) => route.strapiId === id);
        }) ?? [];

    return (
        <Layout>
            <SEO title="Moje trasy" />
        </Layout>
    );
};

export default MyRoutes;
